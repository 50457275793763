<template>
  <section
    v-if="products"
    class="products-week"
    :class="{ 'is-none': !products.length }"
  >
    <div class="container">
      <div
        v-if="products.length"
        class="header-block"
      >
        <h2 class="title">Похожие товары</h2>
      </div>
      <products-list
        v-if="products"
        :items="products"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { getProductsByFilters } from "@/api/products"

export default {
  name: "WeeklyProductsList",
  props: {
    excludeProduct: {
      type: Number,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
    product: {
      type: Object,
      default: null,
    },
    typeOfProduct: {
      // type: any,
      default: null,
    },
  },
  components: {
    ProductsList: () => import("@/components/products/ProductsList.vue"),
  },
  data() {
    return {
      products: null,
    }
  },
  computed: {
    catId() {
      return this.$i18n.locale === "kz" ? 15 : 1
    },
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    excludeProduct: {
      handler() {
        this.getWeeklyProducts(6)
      },
    },
    selectedCity: {
      deep: true,
      handler() {
        this.getWeeklyProducts()
      },
    },
  },
  created() {
    this.getWeeklyProducts()
  },
  mounted() {
    if (this.$router.app._route.fullPath === "/products") {
      this.title = "otherGoods"
    }
  },
  methods: {
    getWeeklyProducts() {
      getProductsByFilters(
        {
          subcategories: [this.typeOfProduct.attributes.subcategories.data[0].id],
          countries: [],
          sugar: [],
          volume: [],
          brand: [],
          vinePlace: [],
          strenght: [],
          shopTypes: [],
          discountTypes: [],
        },
        { field: "id", sortType: "asc" },
        { page: 1, pageSize: this.pageSize },
        this.excludeProduct,
      ).then((data) => {
        this.products = data.data
      })
    },
  },
}
</script>
<style
  lang="scss"
  scoped
></style>
